<!-- eslint-disable -->
<template>
  <div class="main-sidebar-nav default-navigation">
    <div class="nano" id="nano">
      <router-link to="/" class="admin-logo">
          <h1>
            <img alt="" src="/img/icon.png" class="logo-icon margin-r-10 rounded-circle">
          </h1>
          <span class="toggle-none hidden-xs logo-text1">SIAKAD UM METRO</span>
        </router-link>
      <div class="nano-content sidebar-nav">
        <ul id="menu" class="metisMenu nav flex-column mt-20">
          <li class="nav-heading">
            <span>DAFTAR MENU</span>
          </li>
          <li class="nav-item" >
            <router-link to="/" class="nav-link" v-on:click="toggleHide()">
              <i class="icon-home"></i> <span class="toggle-none">Dashboard </span>
            </router-link>
          </li>
          <li class="nav-item" v-bind:class="{ active: navActive('krs') }">
            <a class="nav-link" href="javascript: void(0);" aria-expanded="false"><i class="icon-docs"></i> 
              <span class="toggle-none">Kartu Rencana Studi <span class="fa arrow"></span></span>
            </a>
            <ul class="nav-second-level nav flex-column " aria-expanded="false">
              <li class="nav-item" v-on:click="toggleHide()">
                <router-link to="/entrikrs" class="nav-link">Entri KRS </router-link>
              </li>
              <li class="nav-item" v-on:click="toggleHide()">
                <router-link to="/krs" class="nav-link">Lihat KRS </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item" v-bind:class="{ active: navActive('kpu') }">
            <router-link to="/kpu" class="nav-link" v-on:click="toggleHide()">
              <i class="icon-printer"></i> <span class="toggle-none">Kartu Peserta Ujian </span>
            </router-link>
          </li>
          <li class="nav-item" v-bind:class="{ active: navActive('edom') }">
            <router-link to="/edom/matakuliah" class="nav-link" v-on:click="toggleHide()">
              <i class="icon-user-following" ></i> <span class="toggle-none">Evaluasi Dosen </span>
            </router-link>
          </li>
          <li class="nav-item" v-bind:class="{ active: navActive('nilai') }">
            <a class="nav-link" href="javascript: void(0);" aria-expanded="false"><i class="icon-notebook"></i> 
              <span class="toggle-none">Nilai Akademik<span class="fa arrow"></span></span>
            </a>
            <ul class="nav-second-level nav flex-column sub-menu" aria-expanded="false">
              <li class="nav-item" v-on:click="toggleHide()">
                <router-link to="/khs" class="nav-link">K H S </router-link>
              </li>
              <li class="nav-item" v-on:click="toggleHide()">
                <router-link to="/transkip" class="nav-link">Transkip </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item" v-bind:class="{ active: navActive('perkuliahan') }">
            <a class="nav-link" href="javascript: void(0);" aria-expanded="false"><i class="icon-graduation"></i> 
              <span class="toggle-none">Perkuliahan<span class="fa arrow"></span></span>
            </a>
            <ul class="nav-second-level nav flex-column sub-menu" aria-expanded="false">
              <li class="nav-item" v-on:click="toggleHide()">
                <router-link to="/kelas" class="nav-link">Kelas Perkuliahan</router-link>
              </li>
              <li class="nav-item" v-on:click="toggleHide()">
                <router-link to="/info-akun-spada" class="nav-link">Akun SPADA</router-link>
              </li>
              <li class="nav-item" v-on:click="toggleHide()">
                <router-link to="/list-course-spada" class="nav-link">Enrol Course SPADA</router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item" v-bind:class="{ active: navActive('prestasi') }">
            <a class="nav-link" href="javascript: void(0);" aria-expanded="false"><i class="icon-trophy"></i> <span class="toggle-none">Prestasi<span class="fa arrow"></span></span></a>
            <ul class="nav-second-level nav flex-column sub-menu" aria-expanded="false">
              <li class="nav-item" v-on:click="toggleHide()">
                <router-link to="/prestasi/unggah" class="nav-link">Tambah</router-link>
              </li>
              <li class="nav-item" v-on:click="toggleHide()">
                <router-link to="/prestasi" class="nav-link">Prestasi Disetujui</router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item" v-bind:class="{ active: navActive('pembayaran') }">
            <a class="nav-link" href="javascript: void(0);" aria-expanded="false"><i class="icon-handbag"></i> <span
                class="toggle-none">Keuangan Mahasiswa<span class="fa arrow"></span></span></a>
            <ul class="nav-second-level nav flex-column sub-menu" aria-expanded="false">
              <li class="nav-item" v-on:click="toggleHide()">
                <router-link to="/keuangan/tagihan" class="nav-link">Tagihan Pembayaran </router-link>
              </li>
              <li class="nav-item" v-on:click="toggleHide()">
                <router-link to="/keuangan/buat-tagihan" class="nav-link">Pembuatan Tagihan </router-link>
              </li>
              <li class="nav-item" v-on:click="toggleHide()">
                <router-link to="/keuangan/saldokip" class="nav-link">Saldo Beasiswa / KIP </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
export default {
  name: 'SidebarLeft',
  props: {
    navItem: ''
  },
  mounted() {
    $('.nano').nanoScroller()
    $('#menu').metisMenu()
  },
  methods: {
    navActive: function (menuItem) {
      return this.navItem === menuItem
    },
    toggleHide() {
      const el = document.body;
      el.classList.remove('nav-toggle');
    }
  }
}
</script>
