import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import numeral from 'numeral'
import interceptors from './config/interceptors'

import '@/assets/lib/jasny-bootstrap/css/jasny-bootstrap.min.css'
import '@/assets/lib/bootstrap/css/bootstrap.min.css'
import '@/assets/lib/sweet-alerts2/sweetalert2.min.css'
import '@/assets/lib/toast/jquery.toast.min.css'
import '@/assets/lib/datatables/jquery.dataTables.min.css'
import '@/assets/lib/bootstrap-datepicker/css/bootstrap-datepicker.min.css'
import '@/assets/lib/boostrap-select2/css/select2.min.css'
import '@/assets/lib/boostrap-select2/css/select2-bootstrap4.min.css'
import '@/assets/css/style.css'
import 'vue-loading-overlay/dist/vue-loading.css'

import '@/assets/lib/jquery/dist/jquery.min.js'
import '@/assets/lib/bootstrap/js/bootstrap.min.js'
import '@/assets/lib/jasny-bootstrap/js/jasny-bootstrap.min.js'
import '@/assets/lib/slimscroll/jquery.slimscroll.min.js'
import '@/assets/lib/nano-scroll/jquery.nanoscroller.min.js'
import '@/assets/lib/metisMenu/metisMenu.min.js'
import '@/assets/lib/sweet-alerts2/sweetalert2.min.js'
import '@/assets/lib/toast/jquery.toast.min.js'
import '@/assets/lib/boostrap-select2/js/select2.min.js'
import '@/assets/lib/bootstrap-datepicker/js/bootstrap-datepicker.min.js'
import '@/assets/lib/datatables/jquery.dataTables.min.js'
import '@/assets/lib/datatables/dataTables.responsive.min.js'

interceptors(store)
const app = createApp(App)

app.config.globalProperties.$filters = {
  formatRp (value) {
    return numeral(value).format('0,0')
  },
  formatNumber (value) {
    return numeral(value)
  }
}
app
  .use(store)
  .use(router)
  .mount('#app')
