import store from '../store'
const setRoles = {
  siakadmhs: [
    'dashboard',
    'editbiodata',
    'editprofil',
    'entrikrs',
    'riwayatkrs',
    'kpu',
    'khs',
    'transkip',
    'edom',
    'edominput',
    'akunspada',
    'kelas',
    'registerakunspada',
    'coursespada',
    'infocoursespada',
    'saldokip',
    'infotagihan',
    'buattagihan',
    'paymentkip',
    'slipbmi',
    'slipbmm',
    'slipbsi'
  ],
  adminbaak: [
    'dashboard',
    'prop_penelitian_baru',
    'prop_penelitian_lanjut',
    'lap_penelitian_kemajuan',
    'arsip_penelitian',
    'keanggotaan_penelitian'
  ],
  pustik: [
    'dashboard',
    'prop_penelitian_baru'
  ]
}
export const permission = (mission) => {
  return store.getters.roles.map(role => {
    const permissionRole = setRoles[role]
    return permissionRole.includes(mission)
  })
}

export default setRoles
